import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import moment from "moment";

import styles from "../../styles";

const Title = styled.div`
  padding: 0.5rem;
  width: 33%;
`;
Title.displayName = "Title";

const Body = styled.div`
  width: 66%;
  margin-top: 0;
`;
Body.displayName = "Body";

const Position = styled.h3`
  color: ${styles.colors.gray800};
  font-size: ${styles.text.sizexlg};
  font-weight: 500;
  margin: 0;
  padding: 0;
`;
Position.displayName = "Position";

const Company = styled.h4`
  font-size: ${styles.text.sizebase};
  color: ${styles.colors.gray700};
  font-weight: 300;
  margin: 0.25rem 0 0;
  padding: 0;
`;
Company.displayName = "Company";

const Content = styled.div`
  font-size: ${styles.text.sizebase};
  font-weight: 300;
  margin: 0.75rem 0 0;
  padding: 0;
`;
Content.displayName = "Content";

const Entry = styled.div`
  display: flex;
  padding: 1rem 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: calc(0);
    left: calc(33% - 8px);
    background-color: ${styles.colors.black};
    width: 10px;
    height: 10px;
    border: 4px solid white;
    border-radius: 10px;
  }
`;

const TimelineEntry = ({ startDate, endDate, position, company, children }) => {
  const renderDate = date => {
    const dateTimeFormat = "YYYY-MM-DDThh:mm:ssTZD";
    const textFormat = "MMM YYYY";
    return (
      <time
        css={css`
          white-space: nowrap;
        `}
        datetime={
          date ? moment(date).format(textFormat) : moment().format(textFormat)
        }
      >
        {date ? moment(date).format(textFormat) : "Current"}
      </time>
    );
  };

  return (
    <Entry>
      <Title>
        {renderDate(startDate)} - {renderDate(endDate)}
      </Title>
      <Body>
        <div
          css={css`
            margin: 0 0 0 2rem;
          `}
        >
          <Position>{position}</Position>
          <Company>{company}</Company>
          <Content>{children}</Content>
        </div>
      </Body>
    </Entry>
  );
};

export default TimelineEntry;
