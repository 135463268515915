import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Slice from "../components/slice";
import TextScroll from "../components/textScroll";
import { Timeline, TimelineEntry } from "../components/timeline";
import { ContentSection, HeroSection } from "../components/section";

const IndexPage = ({
  data: {
    prismicHomepage: page,
    allPrismicJob: { jobs },
  },
}) => {
  const slices = page.data.body.map(slice => {
    if (slice.sliceType === "cta") {
      return {
        ...slice,
        primary: {
          ...slice.primary,
          link: { ...slice.primary.cta_link },
        },
      };
    }

    return slice;
  });

  return (
    <Layout>
      <SEO title={"About"} />

      <HeroSection>
        <h2>{page.data.heading}</h2>
        <h3>{page.data.subheading}</h3>
      </HeroSection>
      <ContentSection
        dangerouslySetInnerHTML={{ __html: page.data.content.html }}
      ></ContentSection>
      <Timeline>
        {jobs.map(job => (
          <TimelineEntry
            startDate={job.data.startDate}
            endDate={job.data.endDate}
            position={job.data.position}
            company={job.data.company}
          >
            <div
              dangerouslySetInnerHTML={{ __html: job.data.content.html }}
            ></div>
          </TimelineEntry>
        ))}
      </Timeline>
      {slices.map(slice => (
        <ContentSection>
          <Slice type={slice.sliceType} {...slice.primary} />
        </ContentSection>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicHomepage {
      data {
        heading
        subheading
        content {
          html
          text
        }
        body {
          ... on PrismicHomepageBodyCta {
            sliceType: slice_type
            primary {
              heading: cta_header
              linkText: cta_link_text
              cta_link {
                linkType: link_type
                url
              }
            }
          }
          ... on PrismicHomepageBodyText {
            sliceType: slice_type
            primary {
              text {
                html
                text
              }
            }
          }
        }
      }
    }

    allPrismicJob(sort: { order: DESC, fields: data___end_date }) {
      jobs: nodes {
        data {
          startDate: start_date
          endDate: end_date
          position
          company
          content {
            text
            html
          }
        }
      }
    }
  }
`;

export default IndexPage;
