import React from "react";
import { css } from "@emotion/core";

import styles from "../../styles";

const ctaStyles = css`
  display: display;
  text-align: center;

  ${styles.screens.lg} {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  h4 {
    margin: 2rem 1rem;
    font-size: ${styles.text.size3xl};
  }

  a {
    margin: 0;
    border: 2px solid ${styles.colors.primary};
    border-radius: 2px;
    text-decoration: none;
    color: ${styles.colors.primary};
    font-weight: 500;
    padding: 1rem 4rem;
    transition: all 0.3s ease 0s;

    &:hover {
      color: ${styles.colors.white};
      background-color: ${styles.colors.primary};
    }
  }
`;

const Cta = ({ heading, linkText, link }) => {
  return (
    <div css={ctaStyles}>
      {heading && <h4>{heading}</h4>}
      {link.url && <a href={link.url}>{linkText}</a>}
    </div>
  );
};

Cta.defaultProps = {
  heading: null,
  linkText: null,
  link: {},
};

export default Cta;
