import React from "react";
import styled from "@emotion/styled";

const Timeline = styled.div`
  width: 100%;
  position: relative;
  margin: 2rem 0 0;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 33%;
    bottom: 0px;
    width: 0px;
    border: 1px solid #000;
  }
`;

const TimelineContainer = ({ children }) => {
  return <Timeline>{children}</Timeline>;
};

export default TimelineContainer;
