import React from "react";
import styled from "@emotion/styled";

const Image = styled.div`
  img {
    margin: 0 auto;
    display: block;
  }
`;

const ImageConatiner = ({ image }) => {
  return (
    <Image>
      <figure>
        <img src={image.url} alt={image.alt} />
      </figure>
    </Image>
  );
};

export default ImageConatiner;
