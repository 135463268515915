import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

const Container = styled.div`
  position: relative;
  overflow: hidden;
  white-space: nowrap;
`;

const ScrollContainer = styled.div`
  display: inline-block;
  animation: marquee 25s linear infinite;

  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
`;

const Item = styled.span`
  display: inline-block;
  padding: 0 1rem;
`;

const TextScroll = ({ items }) => {
  const memoizedItems = useMemo(() => items, []);
  return (
    <Container>
      <ScrollContainer>
        {items.map(item => (
          <Item>{item}</Item>
        ))}
      </ScrollContainer>

      <ScrollContainer>
        {items.map(item => (
          <Item>{item}</Item>
        ))}
      </ScrollContainer>
    </Container>
  );
};

export default TextScroll;
