import React from "react";
import Cta from "./cta";
import Text from "./text";
import Image from "./image";

const setSliceType = type => {
  switch (type) {
    case "cta":
      return Cta;
    case "text":
      return Text;
    case "image":
      return Image;
    default:
      return React.Fragment;
  }
};

const SliceContainer = ({ type, ...props }) => {
  const Component = setSliceType(type);

  return <Component {...props}></Component>;
};

export default SliceContainer;
